import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import FirstSection from "./FirstSection";
import SecondSection from "./Second-Section";
import ThirdSection from "./Third-Section";
import FourthSection from "./Fourth-Section";
import Footer from "../Footer";
import SEO from "../SEO";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO
        title="Home"
        description="Empowering Performance through Acceron's Cutting-Edge Lubrication Solutions"
        keywords="performance, acceron, company, lubrication, solutions"
        image="../../assets/images/Acceron/Home/First-Section/first-section-bg.png"
        url="https://www.acceron.co/"
      />
      <Navbar />
      <FirstSection />
      <SecondSection />
      {/* <ThirdSection /> */}
      <FourthSection />
      <Footer />
    </>
  );
}

export default Home;
