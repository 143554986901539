import "./index.scss";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import { get } from "../../../../utils/api";
import SEO from "../../SEO";

function GalleryFolder() {
  useEffect(() => {
    getGalleryData();
  }, []);

  const [data, setData] = useState<any>([]);

  const getGalleryData = async () => {
    try {
      const response = await get("galleries", {});
      setData(
        response.data.data.filter((item: any) => item.status !== "inactive")
      );
      if (response) {
        console.log(
          response.data.data.filter((item: any) => item.status !== "inactive")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [headingLine, setHeadingLine] = useState(HeadingLine);

  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  useEffect(() => {
    if (textColor === "light") {
      setHeadingLine(HeadingLine);
    } else {
      setHeadingLine(HeadingLineWhite);
    }
  }, [textColor]);

  return (
    <>
      <SEO
        title="Gallery"
        description="Gallery"
        keywords="pictures, acceron, company"
        image="../../../assets/images/Acceron/Gallery/DealerMeet.png"
        url="https://www.acceron.co/gallery/list"
      />
      <div
        className="container-fluid gallery-folder-container"
        style={{
          backgroundColor: primaryColor,
          color: textColor === "light" ? "#fff" : "#000",
        }}
      >
        <div className="gallery-folder-main">
          <div className="row  gallery-folder-first-row">
            <div className="d-flex justify-content-start align-items-center product-section-heading">
              <h2>Gallery Update</h2>
              {textColor === "light" ? (
                <img
                  src={HeadingLine}
                  alt=""
                  className="img-fluid headline-image"
                />
              ) : (
                <img
                  src={HeadingLineWhite}
                  alt=""
                  className="img-fluid headline-image"
                />
              )}
            </div>
          </div>

          <div className="row gallery-folder-second-row">
            {data.map((item: any) => {
              return (
                <div
                  className="col-xs-12 col-md-4 col-lg-4 gallery-folder-second-row-col"
                  key={item.album_id}
                >
                  <Link
                    to={`/gallery/details/${item.album_id}`}
                    className="no-text-decoration"
                  >
                    <Card>
                      <div
                        className="card gallery-card"
                        style={{
                          backgroundColor: secondaryColor,
                        }}
                      >
                        <img
                          src={item.images.s3Url}
                          className="card-img-top img-fluid"
                          alt="..."
                        />
                        <div className="card-body gallery-image-card-body">
                          <h5
                            className="card-title gallery-image-card-title"
                            style={{ color: primaryColor }}
                          >
                            {item.album_name}
                          </h5>
                        </div>
                      </div>
                    </Card>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default GalleryFolder;
