import React, { useEffect, useState } from "react";
import "./index.scss";
import EmotionIcon from "../../assets/images/Malayali/First-Section/EMOTION.png";
import { Button, Card, CardContent } from "@mui/material";
import CoolantIcon from "../../../assets/images/Acceron/Products/acceron-coolant.png";
import SABSEIcon from "../../../assets/images/Acceron/Products/coolant-sabse.png";
import TWO_WHEELER_ENGINE_OIL from "../../../assets/images/Acceron/Products/TWO WHEELER ENGINE OIL.png";
import FRONT_FORK_SHOCK_OIL from "../../../assets/images/Acceron/Products/FRONT FORK SHOCK.png";
import COOLANTS_OIL from "../../../assets/images/Acceron/Products/COOLANTS.png";
import CAR_SEGMENT_OIL from "../../../assets/images/Acceron/Products/CAR SEGMENT OIL.png";
import GEAR_OIL from "../../../assets/images/Acceron/Products/GEAR OIL.png";
import GREASES from "../../../assets/images/Acceron/Products/GREASES.png";
import HYDRAULIC_OIL from "../../../assets/images/Acceron/Products/HYDRAULIC OIL.png";
import DIESEL_ENGINE_OIL from "../../../assets/images/Acceron/Products/STATIONARY DIESEL ENGINE OIL.png";
import TRACTOR_ENGINE_OIL from "../../../assets/images/Acceron/Products/TRACTOR ENGINE OIL.png";
import TRANSMISSION_OIL from "../../../assets/images/Acceron/Products/TRANSMISSION OIL.png";
import WET_BRAKE_OIL from "../../../assets/images/Acceron/Products/WET BRAKE OIL UTTO.png";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import { Link, useNavigate } from "react-router-dom";
import { get } from "../../../../utils/api";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";

function ProductFirstSection() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  const [data, setData] = useState<Array<any>>([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchData({}, 1, 20);
  }, []);

  const fetchData = (filter = {}, page_no: number = 1, limit: number = 100) => {
    get("product_category", {
      filter: filter,
      page: page_no,
      limit: limit,
    }).then((response) => {
      setData(
        response.data.data.filter(
          (e: any) => e.status === "active" || e.status === "Active"
        )
      );
      setTotalCount(response.data.totalCount);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="container-fluid product-section-container"
      style={{
        backgroundColor: primaryColor,
        color: textColor === "light" ? "#fff" : "#000",
      }}
    >
      <div className="row product-header-row">
        <div className="d-flex justify-content-start align-items-center product-section-heading">
          <h2 style={{ color: secondaryColor }}>Products</h2>
          {textColor === "light" ? (
            <img
              src={HeadingLine}
              alt=""
              className="img-fluid headline-image"
            />
          ) : (
            <img
              src={HeadingLineWhite}
              alt=""
              className="img-fluid headline-image"
            />
          )}
        </div>
      </div>

      <div className="row product-topic-row">
        <div className="head-and-para">
          <h6 style={{ color: secondaryColor }}>
            Automotive <span>Lubricants</span>
          </h6>
          <p style={{ color: secondaryColor }}>
            Acceron has developed a wide range of engine oil, gear oils,
            greases, and specialities for 2 wheelers, Tractors, Cars, Light and
            Heavy Commercial Vehicles & Tractors. Blended with our advanced
            additive technologies, every product is designed to enhance
            performance, reliability, and longevity for your use.
          </p>
        </div>
      </div>
      {/* ################################################## */}

      {/* {data.map((e, index) => (
        <li key={index}>{e.product_category}</li>
      ))} */}

      <div className="row product-category-row">
        {data.map((e, index) => (
          <div className="product-category-grid col-sm-12 products-image-col">
            <Link
              to={`/products/${e.product_category_id}`}
              className="no-text-decoration"
            >
              <Card sx={{ borderColor: secondaryColor, borderRadius: 0 }}>
                <div
                  className="card product-image-card"
                  style={{
                    // borderColor: secondaryColor,
                    backgroundColor: primaryColor,
                  }}
                >
                  <img
                    src={e.image_url}
                    className="card-img-top img-fluid product-image-card"
                    alt="..."
                  />
                  <div
                    className="card-body product-image-card-body"
                    style={{
                      // borderColor: secondaryColor,
                      height: "20%",
                    }}
                  >
                    <h5
                      className="card-title product-image-card-title"
                      style={{ color: secondaryColor, marginBottom: 0 }}
                    >
                      {e.product_category}
                    </h5>
                  </div>
                </div>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductFirstSection;
