import React from "react";
import logo from "./logo.svg";
import "./App.css";
import MasterLayout from "./master-layout";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./layout/components/Home";
import Navbar from "./layout/components/Navbar/Navbar";
import AboutUs from "./layout/components/About/About";
import ProductCategory from "./layout/components/Product-Category";
import ContactUs from "./layout/components/Contact-Us/ContactUs";
import Careers from "./layout/components/Careers/Careers";
import ProductEnquiry from "./layout/components/Product-Enquiry/ProductEnquiry";
import Products from "./layout/components/Product-Category/productvarity";
import ProductsDetailsPage from "./layout/components/Product-Category/productdetails";
import Gallery from "./layout/components/Gallery-Page/gallery-page";
import NewsPageSection from "./layout/components/News/First-Section-News";
import NewsPage from "./layout/components/News/news";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/product-category" element={<ProductCategory />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/product_enquiry" element={<ProductEnquiry />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/products/:product_category_id" element={<Products />} />
          <Route
            path="/product_details/:product_id"
            element={<ProductsDetailsPage />}
          />
          <Route
            path="/product-category/:product_category_id/details/:product_id"
            element={<ProductsDetailsPage />}
          />
          <Route path="/gallery/*" element={<Gallery />} />
          <Route path="/newspage" element={<NewsPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
