import React from "react";
import InnovationImg from "../../../assets/images/Acceron/Home/Third-Section/innovation.png";
import OwnershipImg from "../../../assets/images/Acceron/Home/Third-Section/ownership.png";
import ResponsiveImg from "../../../assets/images/Acceron/Home/Third-Section/responsiveness.png";
import PerformanceImg from "../../../assets/images/Acceron/Home/Third-Section/performance.png";
import AgilityImg from "../../../assets/images/Acceron/Home/Third-Section/agility.png";
import "./index.scss";

function ThirdSection() {
  return (
    <div className="row third-section-container">
      <div className="col-xs-12 third-section-main">
        <div className="third-section-heading">
          <h2>Our core values</h2>
        </div>
        <div className="d-md-flex d-block justify-content-between align-items-center third-section-features">
          <div className="text-center  third-section-feature">
            <img src={InnovationImg} alt="" />
            <h3>Innovation</h3>
            <p>
              Driving not just product ideas but also innovation in terms of
              process.
            </p>
          </div>
          <div className="text-center  third-section-feature ownership-feature">
            <img src={OwnershipImg} alt="" />
            <h3>Ownership</h3>
            <p>
              Where the vision becomes not just the company goal but the
              individual goal as well.
            </p>
          </div>
          <div className="text-center  third-section-feature">
            <img src={ResponsiveImg} alt="" />
            <h3>Responsiveness</h3>
            <p>Towards employees, customers and all other stakeholders.</p>
          </div>
        </div>
        <div className="d-md-flex d-block justify-content-between align-items-center third-section-features third-section-features-second">
          <div className="text-center  third-section-feature">
            <img src={PerformanceImg} alt="" />
            <h3>Performance Driven</h3>
            <p>
              Rewarding performers across verticals, thereby setting examples
              for leadership.
            </p>
          </div>
          <div className="text-center  third-section-feature">
            <img src={AgilityImg} alt="" />
            <h4>2020</h4>
            <h3>Agility</h3>
            <p>
              Change is constant. This equips the organization to respond
              rapidly to this dynamic world
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;
