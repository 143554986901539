import React from "react";
import AcceronLogo from "../../assets/images/Acceron/Header/acceron-logo.svg";
import AcceronMobileLogo from "../../assets/images/Acceron/Footer/acceron-logo-mobile.png";
import EmailIcon from "../../assets/images/Acceron/Footer/email-icon.png";
import CallIcon from "../../assets/images/Acceron/Footer/call-icon.png";
import InstagramIcon from "../../assets/images/Acceron/Footer/instagram-icon.png";
import FacebookIcon from "../../assets/images/Acceron/Footer/facebook-icon.png";
import TwitterIcon from "../../assets/images/Acceron/Footer/twitter-icon.png";
import LinkedInIcon from "../../assets/images/Acceron/Footer/linkedin-icon.png";
import "./index.scss";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function Footer() {
  const handleEmailLinkClick = () => {
    const email = "info@acceron.co";
    window.open(`mailto:${email}`);
  };

  return (
    <div className="row footer-container">
      <div className="col-xs-12 footer-main">
        <div className="footer-content-container">
          <Button component={Link} to="/home">
            <img
              src={AcceronMobileLogo}
              alt=""
              className="footer-acceron-logo"
            />
          </Button>
          <div className="d-flex footer-pages-section">
            <h2>Pages</h2>
            <Button component={Link} to="/home">
              <p>Home</p>
            </Button>
            <Button component={Link} to="/about_us">
              <p>About Us</p>
            </Button>
            <Button component={Link} to="/product-category">
              <p>Products</p>
            </Button>
            <Button component={Link} to="/careers">
              <p>Careers</p>
            </Button>
          </div>
          <div className="footer-links-section">
            <h2>Stay Connected</h2>
            <div className="footer-links-container">
              <div className="d-flex justify-content-between align-items-center footer-link">
                <Button onClick={handleEmailLinkClick}>
                  <img src={EmailIcon} alt="" />
                  <p>info@acceron.co</p>
                </Button>
              </div>
              <div className="d-flex justify-content-between align-items-center footer-link footer-call-link">
                <Button>
                  <img src={CallIcon} alt="" />
                  <a href="tel:+918305096448">8305096448</a>
                </Button>
              </div>
              <div className="d-flex justify-content-between align-items-center footer-social-links">
                <Link
                  to="https://www.instagram.com/acceronindustries/"
                  target={"new"}
                >
                  <Button>
                    <img src={InstagramIcon} alt="" />
                  </Button>
                </Link>

                <Button>
                  <img src={FacebookIcon} alt="" />
                </Button>
                <Button>
                  <img src={TwitterIcon} alt="" />
                </Button>
                <Link
                  to="https://www.linkedin.com/company/acceron-industries/"
                  target={"new"}
                >
                  <Button>
                    <img src={LinkedInIcon} alt="linkedin-icon" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content-container-mobile">
          <Button component={Link} to="/home">
            <img
              src={AcceronMobileLogo}
              alt=""
              className="footer-acceron-logo"
            />
          </Button>
        </div>
        <div className="footer-links-container-mobile">
          <div className="footer-links-section">
            <h2>Stay Connected</h2>
            <div className="footer-links-container">
              <div className="d-flex justify-content-between align-items-center footer-link">
                <Button>
                  <img src={EmailIcon} alt="" />
                  <p>info@acceron.co</p>
                </Button>
              </div>
              <div className="d-flex justify-content-between align-items-center footer-link footer-call-link">
                <Button>
                  <img src={CallIcon} alt="" />
                  <a href="tel:+918305096448">8305096448</a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
