import React from "react";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-black.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import AcceronBg from "../../../assets/images/Acceron/About/acceron_bg.png";
import Button from "@mui/material/Button";
import "./index.scss";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import { Helmet } from "react-helmet";

function AboutFirstSection() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  return (
    <div
      className="about-first-section-container"
      style={{
        backgroundColor: primaryColor,
        color: textColor === "light" ? "#fff" : "#000",
      }}
    >
      <Helmet>
        <title>Acceron | About</title>
        <meta
          name="description"
          content="Acceron Industries is a trusted provider of a wide range of Engine Oils, Gear Oils, greases, and other fluids and lubes. Blended with our advanced additive technologies, every product is designed to enhance performance, reliability, and longevity for your use."
        />
        <meta name="keywords" content="Acceron About Us" />
        <meta property="og:title" content="About Us" />
        <meta
          property="og:description"
          content="Acceron Industries is a trusted provider of a wide range of Engine Oils, Gear Oils, greases, and other fluids and lubes. Blended with our advanced additive technologies, every product is designed to enhance performance, reliability, and longevity for your use. "
        />
      </Helmet>
      <div className="row d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-start align-items-center fourth-section-heading aboutus-first-heading">
          <h2 style={{ color: secondaryColor }}>
            About <span>Us</span>
          </h2>
          {textColor === "light" ? (
            <img
              src={HeadingLine}
              alt=""
              className="img-fluid headline-image"
            />
          ) : (
            <img
              src={HeadingLineWhite}
              alt=""
              className="img-fluid headline-image"
            />
          )}
        </div>
      </div>
      <div className="row about-first-section-row position-relative">
        <div className="col-lg-6 col-sm-12 about-first-section-main">
          <div className="about-second-section-text">
            <h4 style={{ color: secondaryColor }}>
              <span>Acceron's automotive lubricants:</span>
              Empowering engines, conquering friction, and maximising
              performance with advanced technology.
            </h4>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 about-second-section-main">
          <div className="about-second-section-description">
            <p style={{ color: secondaryColor }}>
              Acceron Industries is a trusted provider of a wide range of Engine
              Oils, Gear Oils, greases, and other fluids and lubes. Blended with
              our advanced additive technologies, every product is designed to
              enhance performance, reliability, and longevity for your use.
            </p>
          </div>
        </div>
        <img src={AcceronBg} alt="" className="acceron-bg" />
      </div>
      <div className="row about-third-section-description">
        <h5 style={{ color: secondaryColor }}>Vision Of Acceron</h5>
        <p style={{ color: secondaryColor }}>
          <span>To become the FASTEST GROWING lubricant company.</span>
          {""} Acceron aspires to be swiftest growing global lubricant company.
          Through innovation and quality, they're dedicated to outpacing the
          competition. With a keen eye on progress, they aim to establish
          themselves as leaders in the global lubricant industry.
        </p>
      </div>
    </div>
  );
}

export default AboutFirstSection;
