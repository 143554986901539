import "./index.scss";
import * as React from "react";
import EmailIcon from "../../../assets/images/Acceron/Contacts/email.png";
import CallIcon from "../../../assets/images/Acceron/Contacts/call.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import styled from "@emotion/styled";
import { Button, FormControl, InputBase, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { awsupload, post } from "../../../../utils/api";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import SnackbarAlert from "../../../../common/sanckbar";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "warning",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFF",
    },
    "&:hover fieldset": {
      borderColor: "#FFF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "warning",
    },
    "& .MuiInputBase-input": {
      color: "white", // Change the text color to white
    },
  },
});

const ContactFirstSection = () => {
  const [headingLine, setHeadingLine] = useState(HeadingLine);

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  useEffect(() => {
    if (textColor === "light") {
      setHeadingLine(HeadingLine);
    } else {
      setHeadingLine(HeadingLineWhite);
    }
  }, [textColor]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
    document_url: "",
    document_id: "",
  });

  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const [loadingLogo, setLoadingLogo] = useState(false);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const handleCloseBackdrop = () => {
    setBackdropOpen(false);
  };
  const handleOpenBackdrop = () => {
    setBackdropOpen(true);
  };

  const [value, setValue] = useState("+91");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleNumberChange = (newValue: any) => {
    console.log(newValue);
    setValue(newValue);
    setFormData({ ...formData, phone_number: newValue });

    setFormErrors({ ...formErrors, phone_number: "" });
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      message: "",
    };

    if (!formData.first_name) {
      errors.first_name = "Name is required!";
      isValid = false;
    } else if (!/[a-zA-Z]/.test(formData.first_name)) {
      errors.first_name = "Invalid name!";
      isValid = false;
    }

    if (!formData.last_name) {
      errors.first_name = "Name is required!";
      isValid = false;
    } else if (!/[a-zA-Z]/.test(formData.last_name)) {
      errors.last_name = "Invalid name!";
      isValid = false;
    }

    if (!formData.email) {
      errors.email = "Email is required!";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email address!";
      isValid = false;
    }

    if (!formData.phone_number) {
      errors.phone_number = "Phone number is required!";
      isValid = false;
    } else if (
      formData.phone_number.replace(/\D/g, "").length < 8 ||
      formData.phone_number.replace(/\D/g, "").length > 17
    ) {
      errors.phone_number = "Invalid phone number!";
      isValid = false;
    }

    if (!formData.message) {
      errors.message = "Message is required!";
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (validateForm()) {
      handleOpenBackdrop();

      try {
        // Assuming `post` is an asynchronous function that sends data to the backend
        const generalEnquiryResponse = await post("general_enquiry", formData);

        const response = post("send-email", { ...formData });
        console.log(generalEnquiryResponse);
        console.log(generalEnquiryResponse.Status);
        // if (response.status === 200 || response.status === 201) {
        if (generalEnquiryResponse) {
          alert("Submitted Successfully", "success");
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            message: "",
            document_url: "",
            document_id: "",
          });
        } else {
          alert("Submission Failed", "error");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred while submitting the form.", "error");
      }
    }
  };

  const uploadFile = async (event: any) => {
    // let file_id: any;
    let folder_name = "general_enquiry";
    setLoadingLogo(true);

    const data1 = new FormData();
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
    };
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    // setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    let queryParams = {
      folder_name: folder_name,
      selectedFile: selectedFile.name,
      file_type: fileType,
    };
    // let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", selectedFile, selectedFile.name);
    awsupload("uploadDocument", data1, queryParams)
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          const newData = {
            ...formData,
            document_url:
              res.data.document_url + "?timestamp=" + new Date().getTime(),
            document_id: res.data.document_id,
          };
          setFormData(newData);
          console.log("newData", newData);
          setLoadingLogo(false);
          alert("File Uploaded Successfully", "success");
        }
      })
      .catch((err: any) => {
        console.error(err);
        alert("File Upload Failed", "error");
      });
  };

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div
        className="container-fluid contact-first-section-container"
        style={{
          backgroundColor: primaryColor,
          color: textColor === "light" ? "#fff" : "#000",
        }}
      >
        <div className="row contact-first-section-row">
          <div className="contact-head-and-para">
            <h6 style={{ color: secondaryColor }}>
              Contact <span>Us</span>
            </h6>
            <p style={{ color: secondaryColor }}>
              To connect with us regarding Acceron, please don't hesitate to
              reach out. We're here to help and provide information.
            </p>
          </div>
        </div>

        <div className="row contact-link-row">
          <div className="d-flex  align-items-center">
            <img src={EmailIcon} alt="" />
            <h2 style={{ color: secondaryColor }}>
              customer.support@acceron.co
            </h2>
          </div>
        </div>

        <div className="row contact-link-row">
          <div className="d-flex  align-items-center fourth-section-heading">
            <img src={CallIcon} alt="" />
            <h2 style={{ color: secondaryColor }}>8305096448</h2>
          </div>
        </div>

        <div className="row">
          <div className="d-flex justify-content-start align-items-center fourth-section-heading">
            <h2 style={{ color: secondaryColor }}>
              Leave a <span>Message</span>
            </h2>
            <img src={headingLine} alt="" className="headline-image" />
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row contact-second-section-row">
            <div className="col-lg-6 col-sm-12 contact-field-col">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <TextField
                  className="contact-box"
                  id="outlined-multiline-flexible"
                  label="First Name"
                  name="first_name"
                  multiline
                  maxRows={4}
                  color="warning"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  required
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </Box>
            </div>
            <div className="col-lg-6 col-sm-12 contact-field-col">
              <Box
                component="div"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <TextField
                  className="contact-box"
                  id="outlined-multiline-flexible"
                  label="Last Name"
                  name="last_name"
                  multiline
                  maxRows={4}
                  color="warning"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  required
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </Box>
            </div>
          </div>

          <div className="row contact-second-section-row">
            <div className="col-lg-6 col-sm-12 contact-field-col">
              <Box
                component="div"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <TextField
                  className="contact-box"
                  id="outlined-multiline-flexible"
                  label="Email Id"
                  name="email"
                  multiline
                  maxRows={4}
                  color="warning"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </Box>
            </div>
            <div className="col-lg-6 col-sm-12 contact-field-col">
              <Box
                component="div"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <TextField
                  className="contact-box"
                  id="outlined-multiline-flexible"
                  label="Phone Number"
                  name="phone_number"
                  multiline
                  color="warning"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </Box>
            </div>
          </div>

          <div className="row contact-second-section-row">
            <div className="col-12 contact-field-col">
              <Box
                component="div"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <TextField
                  className="contact-box"
                  id="outlined-multiline-static"
                  label="Message"
                  name="message"
                  multiline
                  rows={6}
                  defaultValue=""
                  color="warning"
                  value={formData.message}
                  onChange={handleInputChange}
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </Box>
            </div>
          </div>

          <div className="row contact-second-section-row">
            <div className="col-12 resume-upload-col">
              <p
                style={{
                  color: "#000000",
                  marginBottom: 0,
                  marginRight: "1rem",
                }}
              >
                Upload your file
              </p>
              <FormControl>
                <Paper
                  className="resume-upload"
                  component="form"
                  sx={{
                    // p: "2px 4px",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label htmlFor="file-upload">
                    <Button
                      variant="contained"
                      component="span"
                      color="warning"
                      // onClick={handleButtonClick}
                      sx={{
                        color: secondaryColor,
                        " & :hover": {
                          backgroundColor: secondaryColor,
                        },
                      }}
                    >
                      <InputBase
                        id="file-upload"
                        type="file"
                        inputProps={{ accept: ".docx,.pdf" }}
                        style={{ display: "none" }}
                        onChange={uploadFile}
                      />
                      Upload
                    </Button>
                  </label>
                </Paper>
              </FormControl>
              {/* </Box> */}
            </div>
          </div>

          <div className="row send-btn-row">
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#FF6B00",
                "&:hover": {
                  backgroundColor: "#FF6B00",
                },
              }}
            >
              <span style={{ color: secondaryColor }}>SUBMIT MESSAGE</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactFirstSection;
